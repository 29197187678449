<template>
  <v-card class="rounded-lg pa-6">
    <v-btn class="close-button" icon @click="$emit('input', false)">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-row>
      <v-col id="titleText" cols="12" class="text-center text-h5">{{
        $t("confirmLoginDetails.enterNewEmailTitle")
      }}</v-col>
      <v-col cols="12"
        ><v-form ref="form" v-model="valid" @submit.prevent>
          <v-text-field
            id="newEmail"
            filled
            :label="$t('confirmLoginDetails.labels.newEmail')"
            :rules="emailRules"
            v-model="newEmail"
            type="email"
            prepend-inner-icon="email"
          />
          <v-text-field
            id="password"
            filled
            :label="$t('confirmLoginDetails.labels.currentPassword')"
            v-model="password"
            type="password"
            prepend-inner-icon="lock"
            :rules="passwordRules"
            @keydown.enter="updateEmail()"
            @input="errorMessage = null"
            hide-details="auto"
            autocomplete="new-password"
          /> </v-form
      ></v-col>
      <v-col cols="12" v-show="errorMessage"
        ><alert-message
          id="alertMessage"
          :message="errorMessage"
          type="error"
        ></alert-message
      ></v-col>
      <v-col cols="12"
        ><v-row
          ><v-col
            ><v-btn
              id="confirmButton"
              @click="updateEmail()"
              width="100%"
              color="primary"
              rounded
              ripple
              :loading="inProgress"
              >{{ $t("confirmLoginDetails.button") }}</v-btn
            ></v-col
          >
        </v-row></v-col
      >
    </v-row>
  </v-card>
</template>

<script>
import { Auth } from "aws-amplify";
import Rules from "@/utils/stringValidation";
import AlertMessage from "@/components/account/AlertMessage";
import { updateEmailAddress } from "@/customApi";
import { mapGetters } from "vuex";

export default {
  components: {
    AlertMessage
  },
  data() {
    return {
      inProgress: false,
      valid: true,
      newEmail: "",
      password: "",
      errorMessage: "",
      user: null,
      emailRules: [Rules.emailRequired, Rules.emailValid],
      passwordRules: [Rules.passwordRequired]
    };
  },
  computed: {
    ...mapGetters(["userEmail"])
  },
  methods: {
    async updateEmail() {
      try {
        this.inProgress = true;
        this.$refs.form.validate();
        if (!this.valid) {
          return;
        }

        if (this.userEmail === this.newEmail) {
          this.errorMessage = this.$t("confirmLoginDetails.sameEmail");
          return;
        }

        this.user = await Auth.signIn(this.userEmail, this.password);

        await updateEmailAddress({
          email: { value: this.newEmail },
          password: this.password,
          accessToken: this.user.signInUserSession.accessToken.jwtToken
        });
        await Auth.signIn(this.newEmail, this.password);
        location.reload();
      } catch (ex) {
        this.$logger.captureException(ex);

        if (ex.code === "NotAuthorizedException") {
          this.errorMessage = this.$t("confirmLoginDetails.incorrectPassword");
          return;
        }

        this.errorMessage = this.$t("authentication.genericErrorMessage");
      } finally {
        this.inProgress = false;
      }
    }
  }
};
</script>
