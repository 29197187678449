import { render, staticRenderFns } from "./AddSlack.vue?vue&type=template&id=686882f2&"
import script from "./AddSlack.vue?vue&type=script&lang=js&"
export * from "./AddSlack.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VImg,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src228135001/src/vitrueremote/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('686882f2')) {
      api.createRecord('686882f2', component.options)
    } else {
      api.reload('686882f2', component.options)
    }
    module.hot.accept("./AddSlack.vue?vue&type=template&id=686882f2&", function () {
      api.rerender('686882f2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/slack/AddSlack.vue"
export default component.exports