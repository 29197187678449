<script>
import {
  loggedInEndUserIsSlackAuthorized,
  authorizeSlackUser,
  userAssessmentIsSlackAuthorized,
  authorizeSlackUserFromAssessment
} from "@/customApi";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "SlackAuth",
  data() {
    return {
      token: null,
      redirect: process.env.VUE_APP_REDIRECT_URL,
      clientId: process.env.VUE_APP_SLACK_PUBLIC_API_KEY,
      authorized: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters(["slackRedirectCode"]),
    authURL() {
      return `https://slack.com/oauth/v2/authorize?scope=incoming-webhook&user_scope=identity.basic&redirect_uri=${encodeURIComponent(
        this.redirect
      )}&client_id=${this.clientId}`;
    }
  },
  async mounted() {
    if (this.slackRedirectCode) {
      this.authorizeFromCode();
    } else {
      this.authorized = this.assessmentId
        ? await userAssessmentIsSlackAuthorized(this.assessmentId)
        : await loggedInEndUserIsSlackAuthorized();
    }
  },
  methods: {
    ...mapMutations(["setRedirectCode"]),
    setStorage() {
      sessionStorage.setItem("nonAuthRedirect", this.$route.path);
    },
    sendToAuth() {
      this.setStorage();
      window.location.href = this.authURL;
    },
    async authorizeFromCode() {
      try {
        this.loading = true;
        if (this.assessmentId) {
          await authorizeSlackUserFromAssessment(
            this.assessmentId,
            this.slackRedirectCode
          );
        } else {
          await authorizeSlackUser(this.slackRedirectCode);
        }
        this.authorized = true;
      } catch (err) {
        this.$emit("error", "slackAuthError");
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
