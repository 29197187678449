var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _vm.showEmailUpdateDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "400", persistent: "" },
              model: {
                value: _vm.showEmailUpdateDialog,
                callback: function($$v) {
                  _vm.showEmailUpdateDialog = $$v
                },
                expression: "showEmailUpdateDialog"
              }
            },
            [
              _vm.showEmailUpdateDialog
                ? _c("update-email-dialog", {
                    attrs: { email: _vm.userEmail },
                    model: {
                      value: _vm.showEmailUpdateDialog,
                      callback: function($$v) {
                        _vm.showEmailUpdateDialog = $$v
                      },
                      expression: "showEmailUpdateDialog"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "pt-4" },
        [
          _c(
            "v-col",
            { staticClass: "text-h5 black--text pb-0", attrs: { cols: "12" } },
            [_vm._v(_vm._s(_vm.$t("account.personalSettings.title")))]
          ),
          _vm.testFeature
            ? _c("v-card", [_vm._v(" Hi " + _vm._s(_vm.userEmail) + " ")])
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "pb-2",
                  attrs: { justify: "start", align: "end" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0",
                      attrs: { id: "emailText", cols: "auto" }
                    },
                    [
                      _c("i18n", {
                        staticClass: "black--text my-0",
                        attrs: { path: "account.personalSettings.emailText" },
                        scopedSlots: _vm._u([
                          {
                            key: "email",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v(_vm._s(_vm.userEmail))]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm.userNameIsEmail
                    ? _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "px-0",
                              attrs: {
                                id: "updateEmailButton",
                                color: "primary",
                                bottom: "",
                                text: "",
                                "x-small": ""
                              },
                              on: {
                                click: function($event) {
                                  _vm.showEmailUpdateDialog = true
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("buttons.change")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: _vm.textFieldColumnSize } },
            [
              _c(
                "v-form",
                {
                  ref: "personalSettingsForm",
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      id: "nameField",
                      outlined: "",
                      label: _vm.$t(
                        "account.personalSettings.textFieldLabels.name"
                      ),
                      "prepend-inner-icon": "mdi-account",
                      disabled: _vm.loadingDataFailed,
                      rules: [
                        _vm.rules.nameValid(_vm.$t("inputRules.nameFormat"))
                      ]
                    },
                    model: {
                      value: _vm.newData.name,
                      callback: function($$v) {
                        _vm.$set(_vm.newData, "name", $$v)
                      },
                      expression: "newData.name"
                    }
                  }),
                  _c("VuePhoneNumberInput", {
                    staticClass: "mb-6",
                    attrs: {
                      id: "numberField",
                      "default-country-code": _vm.newData.countryCode,
                      size: "lg",
                      color: "#63a8ff",
                      "valid-color": "#4CAF50",
                      "error-color": "#ff0c3e",
                      loader: _vm.loadingData,
                      "no-validator-state": "",
                      error: !_vm.newData.validNumber && !!_vm.newData.number,
                      disabled: _vm.loadingDataFailed,
                      translations: {
                        countrySelectorLabel: _vm.$t(
                          "phoneNumberComponent.countryCode"
                        ),
                        countrySelectorError: _vm.$t(
                          "phoneNumberComponent.countrySelectError"
                        ),
                        phoneNumberLabel: _vm.$t(
                          "phoneNumberComponent.phoneNumber"
                        ),
                        example: _vm.$t("phoneNumberComponent.example")
                      }
                    },
                    on: {
                      update: function($event) {
                        return _vm.setTelephoneInfo($event)
                      }
                    },
                    model: {
                      value: _vm.newData.number,
                      callback: function($$v) {
                        _vm.$set(_vm.newData, "number", $$v)
                      },
                      expression: "newData.number"
                    }
                  }),
                  !_vm.hideSaveButton
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            id: "saveButton",
                            color: "primary",
                            bottom: "",
                            small: "",
                            loading: _vm.savingResults,
                            width: "150px"
                          },
                          on: {
                            click: function($event) {
                              return _vm.saveResults()
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("buttons.save")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-h5 black--text",
                      attrs: { cols: "12" }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("account.personalSettings.appSettingsTitle")
                        )
                      )
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0 my-0",
                      attrs: { cols: _vm.textFieldColumnSize }
                    },
                    [_c("LanguageSelection", { attrs: { largeLayout: "" } })],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "black--text mt-8", attrs: { cols: "12" } },
            [
              _c("p", { staticClass: "text-h5 mb-1" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("account.personalSettings.notificationsTitle")
                    ) +
                    " "
                )
              ]),
              _c("p", { staticClass: "text-body-2 mb-1 font-italic" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "account.personalSettings.notificationsExplanation"
                      )
                    ) +
                    " "
                )
              ])
            ]
          ),
          _c(
            "v-col",
            {
              staticClass: "text-body-2 black--text py-0",
              attrs: { cols: "12", md: "auto" }
            },
            _vm._l(_vm.notificationTypes, function(notificationType) {
              return _c(
                "v-row",
                {
                  key: notificationType.settingsDto.notificationName,
                  attrs: {
                    justify: "space-between",
                    align: "center",
                    "no-gutters": ""
                  }
                },
                [
                  (notificationType.adminOnly
                  ? _vm.isAdminUser
                  : true)
                    ? _c(
                        "v-col",
                        { staticClass: "mr-4", attrs: { cols: "auto" } },
                        [_vm._v(" " + _vm._s(_vm.$t(notificationType.text)))]
                      )
                    : _vm._e(),
                  (notificationType.adminOnly
                  ? _vm.isAdminUser
                  : true)
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                justify: "start",
                                align: "center",
                                "no-gutters": ""
                              }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "mr-2",
                                  attrs: { cols: "auto" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "account.personalSettings.toggleOptions.email"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "mr-2",
                                  attrs: { cols: "auto" }
                                },
                                [
                                  _c("v-switch", {
                                    staticClass: "mt-1 pt-0",
                                    attrs: {
                                      id: "painCoachEmail",
                                      dense: "",
                                      "hide-details": "",
                                      color: "secondary",
                                      disabled:
                                        notificationType.settingsDto.email ===
                                        undefined
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.saveNotificationSettings(
                                          notificationType.settingsDto
                                        )
                                      }
                                    },
                                    model: {
                                      value: notificationType.settingsDto.email,
                                      callback: function($$v) {
                                        _vm.$set(
                                          notificationType.settingsDto,
                                          "email",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "notificationType.settingsDto.email"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "mr-2",
                                  attrs: { cols: "auto" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "account.personalSettings.toggleOptions.sms"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "mr-2",
                                  attrs: { cols: "auto" }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        right: "",
                                        disabled:
                                          !_vm.disableTextOptIn &&
                                          _vm.showTextNotifications,
                                        color: "transparent"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "div",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("v-switch", {
                                                      staticClass: "mt-1 pt-0",
                                                      attrs: {
                                                        id: "painCoachText",
                                                        dense: "",
                                                        "hide-details": "",
                                                        color: "secondary",
                                                        disabled:
                                                          _vm.disableTextOptIn ||
                                                          !_vm.showTextNotifications
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.saveNotificationSettings(
                                                            notificationType.settingsDto
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          notificationType
                                                            .settingsDto.text,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            notificationType.settingsDto,
                                                            "text",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "notificationType.settingsDto.text"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("vitrue-tooltip", {
                                        attrs: {
                                          message: _vm.showTextNotifications
                                            ? _vm.$t(
                                                "account.personalSettings.disabledTextTooltip"
                                              )
                                            : _vm.$t(
                                                "account.personalSettings.textUnreleased"
                                              )
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            1
          ),
          _vm.showTextNotifications
            ? _c(
                "v-col",
                {
                  staticClass: "text-body-2 black--text pt-8",
                  attrs: { cols: "12" }
                },
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        justify: _vm.$vuetify.breakpoint.xs
                          ? "center"
                          : "start",
                        align: "center",
                        "no-gutters": ""
                      }
                    },
                    [
                      _c("v-col", { attrs: { cols: "12", md: "auto" } }, [
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "account.personalSettings.notificationTime"
                                )
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c(
                        "v-col",
                        {
                          staticClass: "px-2 my-2",
                          attrs: {
                            cols: "12",
                            sm: "6",
                            md: "4",
                            lg: "3",
                            xl: "2"
                          }
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: {
                                bottom: "",
                                disabled: !_vm.disableTextTimePreferences,
                                color: "transparent"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            _vm._b({}, "div", attrs, false),
                                            on
                                          ),
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                id: "notificationTime",
                                                label: _vm.$t(
                                                  "account.personalSettings.timeLabel"
                                                ),
                                                items: _vm.notificationTimes,
                                                "item-text": "text",
                                                "item-value": "value",
                                                outlined: "",
                                                "hide-details": "",
                                                disabled:
                                                  _vm.disableTextTimePreferences,
                                                dense: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.saveNotificationSettings(
                                                    _vm.notificationTypes[0]
                                                      .settingsDto
                                                  )
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.notificationTypes[0]
                                                    .settingsDto.time,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.notificationTypes[0]
                                                      .settingsDto,
                                                    "time",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "notificationTypes[0].settingsDto.time"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1779630815
                              )
                            },
                            [
                              _c("vitrue-tooltip", {
                                attrs: {
                                  message: _vm.disableTextOptIn
                                    ? _vm.$t(
                                        "account.personalSettings.disabledTextTooltip"
                                      )
                                    : _vm.$t(
                                        "account.personalSettings.disabledTimePrefenceTooltip"
                                      )
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "px-2 my-2",
                          attrs: {
                            cols: "12",
                            sm: "6",
                            md: "4",
                            lg: "3",
                            xl: "2"
                          }
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: {
                                bottom: "",
                                disabled: !_vm.disableTextTimePreferences,
                                color: "transparent"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            _vm._b({}, "div", attrs, false),
                                            on
                                          ),
                                          [
                                            _c("v-select", {
                                              staticClass: "hideOverflow",
                                              attrs: {
                                                id: "notificationTimeZone",
                                                label: _vm.$t(
                                                  "account.personalSettings.timeZoneLabel"
                                                ),
                                                items:
                                                  _vm.notificationTimeZones,
                                                "item-value": "value",
                                                "item-text": "text",
                                                outlined: "",
                                                "hide-details": "",
                                                dense: "",
                                                disabled:
                                                  _vm.disableTextTimePreferences
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.savePersonalInfo()
                                                }
                                              },
                                              model: {
                                                value: _vm.newData.timeZone,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.newData,
                                                    "timeZone",
                                                    $$v
                                                  )
                                                },
                                                expression: "newData.timeZone"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3732207473
                              )
                            },
                            [
                              _c("vitrue-tooltip", {
                                attrs: {
                                  message: _vm.disableTextOptIn
                                    ? _vm.$t(
                                        "account.personalSettings.disabledTextTooltip"
                                      )
                                    : _vm.$t(
                                        "account.personalSettings.disabledTimePrefenceTooltip"
                                      )
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.vitrueDeveloper
            ? _c(
                "v-col",
                { staticClass: "black--text mt-8", attrs: { cols: "12" } },
                [
                  _c("p", { staticClass: "text-h5 mb-1" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("account.personalSettings.integrations.title")
                        ) +
                        " "
                    )
                  ]),
                  _c("p", { staticClass: "text-body-2 mb-8" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "account.personalSettings.integrations.explanation"
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c("add-slack", {
                    on: {
                      error: function($event) {
                        return _vm.logAndShowError($event)
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.isAdminUser
            ? _c(
                "v-col",
                { staticClass: "black--text mt-8", attrs: { cols: "12" } },
                [
                  _c("p", { staticClass: "text-h5" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("account.personalSettings.quickStartTitle")
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "v-row",
                    {
                      attrs: {
                        justify: _vm.$vuetify.breakpoint.xs
                          ? "center"
                          : "start",
                        align: "center",
                        "no-gutters": ""
                      }
                    },
                    [
                      _c("v-col", { attrs: { cols: "auto" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("account.personalSettings.quickStartToggle")
                          )
                        )
                      ]),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-switch", {
                            staticClass: "mt-1 pt-0 pl-2",
                            attrs: {
                              id: "quickStartToggle",
                              dense: "",
                              "hide-details": "",
                              color: "secondary"
                            },
                            on: {
                              change: function($event) {
                                return _vm.toggleQuickStart()
                              }
                            },
                            model: {
                              value: _vm.adminChecklistToggle,
                              callback: function($$v) {
                                _vm.adminChecklistToggle = $$v
                              },
                              expression: "adminChecklistToggle"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-snackbar",
            {
              attrs: { timeout: "2500", color: "warning" },
              scopedSlots: _vm._u([
                {
                  key: "action",
                  fn: function(ref) {
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._b(
                          {
                            attrs: { dark: "", text: "" },
                            on: {
                              click: function($event) {
                                _vm.showErrorMessage = false
                              }
                            }
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.showErrorMessage,
                callback: function($$v) {
                  _vm.showErrorMessage = $$v
                },
                expression: "showErrorMessage"
              }
            },
            [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
          ),
          _c(
            "v-snackbar",
            {
              attrs: { timeout: "2500", color: "success" },
              scopedSlots: _vm._u([
                {
                  key: "action",
                  fn: function(ref) {
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-btn",
                        _vm._b(
                          {
                            attrs: { dark: "", text: "" },
                            on: {
                              click: function($event) {
                                _vm.showSaveMessage = false
                              }
                            }
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.showSaveMessage,
                callback: function($$v) {
                  _vm.showSaveMessage = $$v
                },
                expression: "showSaveMessage"
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "account.personalSettings.snackBarMessages.saveSuccessful"
                    )
                  ) +
                  " "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }