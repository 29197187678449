<template>
  <v-row align="center">
    <v-img
      class="ml-3"
      src="@/assets/images/emojis/slack.svg"
      max-width="40"
      contain
    />
    <span class="text-h5 mx-4">Slack</span>
    <v-btn outlined @click="actionHandler" :loading="loading">{{
      action
    }}</v-btn>
  </v-row>
</template>

<script>
import SlackAuth from "./SlackAuth.vue";
import { revokeUserSlackTokens } from "@/customApi";

export default {
  name: "AddSlack",
  mixins: [SlackAuth],
  computed: {
    action() {
      return this.authorized
        ? this.$t("account.personalSettings.integrations.revoke")
        : this.$t("account.personalSettings.integrations.add");
    }
  },
  watch: {
    authorized(newVal) {
      if (newVal && this.slackRedirectCode) {
        this.setRedirectCode(null);
      }
    }
  },
  methods: {
    async actionHandler() {
      if (!this.authorized) {
        this.sendToAuth();
      } else {
        await this.revokeSlack();
      }
    },
    async revokeSlack() {
      try {
        this.loading = true;
        await revokeUserSlackTokens();
        this.authorized = false;
      } catch (err) {
        this.$emit("error", "slackRevokeError");
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
