var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { align: "center" } },
    [
      _c("v-img", {
        staticClass: "ml-3",
        attrs: {
          src: require("@/assets/images/emojis/slack.svg"),
          "max-width": "40",
          contain: ""
        }
      }),
      _c("span", { staticClass: "text-h5 mx-4" }, [_vm._v("Slack")]),
      _c(
        "v-btn",
        {
          attrs: { outlined: "", loading: _vm.loading },
          on: { click: _vm.actionHandler }
        },
        [_vm._v(_vm._s(_vm.action))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }