var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "rounded-lg pa-6" },
    [
      _c(
        "v-btn",
        {
          staticClass: "close-button",
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("input", false)
            }
          }
        },
        [_c("v-icon", [_vm._v("mdi-close")])],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "text-center text-h5",
              attrs: { id: "titleText", cols: "12" }
            },
            [_vm._v(_vm._s(_vm.$t("confirmLoginDetails.enterNewEmailTitle")))]
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      id: "newEmail",
                      filled: "",
                      label: _vm.$t("confirmLoginDetails.labels.newEmail"),
                      rules: _vm.emailRules,
                      type: "email",
                      "prepend-inner-icon": "email"
                    },
                    model: {
                      value: _vm.newEmail,
                      callback: function($$v) {
                        _vm.newEmail = $$v
                      },
                      expression: "newEmail"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      id: "password",
                      filled: "",
                      label: _vm.$t(
                        "confirmLoginDetails.labels.currentPassword"
                      ),
                      type: "password",
                      "prepend-inner-icon": "lock",
                      rules: _vm.passwordRules,
                      "hide-details": "auto",
                      autocomplete: "new-password"
                    },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.updateEmail()
                      },
                      input: function($event) {
                        _vm.errorMessage = null
                      }
                    },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errorMessage,
                  expression: "errorMessage"
                }
              ],
              attrs: { cols: "12" }
            },
            [
              _c("alert-message", {
                attrs: {
                  id: "alertMessage",
                  message: _vm.errorMessage,
                  type: "error"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            id: "confirmButton",
                            width: "100%",
                            color: "primary",
                            rounded: "",
                            ripple: "",
                            loading: _vm.inProgress
                          },
                          on: {
                            click: function($event) {
                              return _vm.updateEmail()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("confirmLoginDetails.button")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }