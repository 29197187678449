<template>
  <v-container fluid class="pa-0">
    <v-dialog
      v-if="showEmailUpdateDialog"
      v-model="showEmailUpdateDialog"
      max-width="400"
      persistent
    >
      <update-email-dialog
        v-if="showEmailUpdateDialog"
        v-model="showEmailUpdateDialog"
        :email="userEmail"
      />
    </v-dialog>
    <v-row class="pt-4">
      <v-col cols="12" class="text-h5 black--text pb-0">{{
        $t("account.personalSettings.title")
      }}</v-col>

      <v-card v-if="testFeature"> Hi {{ userEmail }} </v-card>

      <v-col cols="12"
        ><v-row justify="start" align="end" class="pb-2"
          ><v-col id="emailText" cols="auto" class="pb-0">
            <i18n
              class="black--text my-0"
              path="account.personalSettings.emailText"
            >
              <template v-slot:email>
                <span class="font-weight-bold">{{ userEmail }}</span>
              </template>
            </i18n>
          </v-col>
          <v-col v-if="userNameIsEmail" cols="auto" class="pb-0">
            <v-btn
              id="updateEmailButton"
              color="primary"
              class="px-0"
              bottom
              text
              x-small
              @click="showEmailUpdateDialog = true"
            >
              {{ $t("buttons.change") }}
            </v-btn>
          </v-col></v-row
        ></v-col
      >

      <v-col :cols="textFieldColumnSize">
        <v-form ref="personalSettingsForm" v-model="valid">
          <v-text-field
            id="nameField"
            outlined
            :label="$t('account.personalSettings.textFieldLabels.name')"
            prepend-inner-icon="mdi-account"
            :disabled="loadingDataFailed"
            v-model="newData.name"
            :rules="[rules.nameValid($t('inputRules.nameFormat'))]"
          />
          <VuePhoneNumberInput
            id="numberField"
            class="mb-6"
            v-model="newData.number"
            :default-country-code="newData.countryCode"
            size="lg"
            color="#63a8ff"
            valid-color="#4CAF50"
            error-color="#ff0c3e"
            :loader="loadingData"
            no-validator-state
            :error="!newData.validNumber && !!newData.number"
            @update="setTelephoneInfo($event)"
            :disabled="loadingDataFailed"
            :translations="{
              countrySelectorLabel: $t('phoneNumberComponent.countryCode'),
              countrySelectorError: $t(
                'phoneNumberComponent.countrySelectError'
              ),
              phoneNumberLabel: $t('phoneNumberComponent.phoneNumber'),
              example: $t('phoneNumberComponent.example')
            }"
          />
          <v-btn
            v-if="!hideSaveButton"
            id="saveButton"
            color="primary"
            bottom
            small
            @click="saveResults()"
            :loading="savingResults"
            width="150px"
          >
            {{ $t("buttons.save") }}
          </v-btn>
        </v-form>
      </v-col>

      <v-col cols="12">
        <v-row
          ><v-col cols="12" class="text-h5 black--text">{{
            $t("account.personalSettings.appSettingsTitle")
          }}</v-col>
          <v-col :cols="textFieldColumnSize" class="py-0 my-0"
            ><LanguageSelection largeLayout /></v-col
        ></v-row>
      </v-col>

      <v-col cols="12" class="black--text mt-8">
        <p class="text-h5 mb-1">
          {{ $t("account.personalSettings.notificationsTitle") }}
        </p>
        <p class="text-body-2 mb-1 font-italic">
          {{ $t("account.personalSettings.notificationsExplanation") }}
        </p>
      </v-col>

      <v-col cols="12" md="auto" class="text-body-2 black--text py-0">
        <v-row
          justify="space-between"
          align="center"
          no-gutters
          v-for="notificationType in notificationTypes"
          :key="notificationType.settingsDto.notificationName"
        >
          <v-col
            cols="auto"
            class="mr-4"
            v-if="notificationType.adminOnly ? isAdminUser : true"
          >
            {{ $t(notificationType.text) }}</v-col
          >
          <v-col
            v-if="notificationType.adminOnly ? isAdminUser : true"
            cols="auto"
            ><v-row justify="start" align="center" no-gutters
              ><v-col cols="auto" class="mr-2">{{
                $t("account.personalSettings.toggleOptions.email")
              }}</v-col
              ><v-col cols="auto" class="mr-2"
                ><v-switch
                  id="painCoachEmail"
                  class="mt-1 pt-0"
                  dense
                  v-model="notificationType.settingsDto.email"
                  hide-details
                  color="secondary"
                  :disabled="notificationType.settingsDto.email === undefined"
                  @change="
                    saveNotificationSettings(notificationType.settingsDto)
                  "
              /></v-col>

              <v-col cols="auto" class="mr-2">{{
                $t("account.personalSettings.toggleOptions.sms")
              }}</v-col>
              <v-col cols="auto" class="mr-2">
                <v-tooltip
                  right
                  :disabled="!disableTextOptIn && showTextNotifications"
                  color="transparent"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on" v-bind="attrs">
                      <v-switch
                        id="painCoachText"
                        class="mt-1 pt-0"
                        dense
                        hide-details
                        color="secondary"
                        v-model="notificationType.settingsDto.text"
                        :disabled="disableTextOptIn || !showTextNotifications"
                        @change="
                          saveNotificationSettings(notificationType.settingsDto)
                        "
                      />
                    </div>
                  </template>
                  <vitrue-tooltip
                    :message="
                      showTextNotifications
                        ? $t('account.personalSettings.disabledTextTooltip')
                        : $t('account.personalSettings.textUnreleased')
                    "
                  /> </v-tooltip
              ></v-col> </v-row
          ></v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        class="text-body-2 black--text pt-8"
        v-if="showTextNotifications"
      >
        <v-row
          :justify="$vuetify.breakpoint.xs ? 'center' : 'start'"
          align="center"
          no-gutters
        >
          <v-col cols="12" md="auto">
            <p class="mb-0">
              {{ $t("account.personalSettings.notificationTime") }}
            </p>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="px-2 my-2">
            <v-tooltip
              bottom
              :disabled="!disableTextTimePreferences"
              color="transparent"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-select
                    id="notificationTime"
                    :label="$t('account.personalSettings.timeLabel')"
                    v-model="notificationTypes[0].settingsDto.time"
                    :items="notificationTimes"
                    item-text="text"
                    item-value="value"
                    outlined
                    hide-details
                    :disabled="disableTextTimePreferences"
                    dense
                    @change="
                      saveNotificationSettings(notificationTypes[0].settingsDto)
                    "
                  ></v-select>
                </div>
              </template>
              <vitrue-tooltip
                :message="
                  disableTextOptIn
                    ? $t('account.personalSettings.disabledTextTooltip')
                    : $t('account.personalSettings.disabledTimePrefenceTooltip')
                "
              />
            </v-tooltip>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="px-2 my-2">
            <v-tooltip
              bottom
              :disabled="!disableTextTimePreferences"
              color="transparent"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-select
                    id="notificationTimeZone"
                    :label="$t('account.personalSettings.timeZoneLabel')"
                    v-model="newData.timeZone"
                    :items="notificationTimeZones"
                    item-value="value"
                    item-text="text"
                    outlined
                    hide-details
                    dense
                    class="hideOverflow"
                    :disabled="disableTextTimePreferences"
                    @change="savePersonalInfo()"
                  >
                  </v-select>
                </div>
              </template>
              <vitrue-tooltip
                :message="
                  disableTextOptIn
                    ? $t('account.personalSettings.disabledTextTooltip')
                    : $t('account.personalSettings.disabledTimePrefenceTooltip')
                "
              />
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="black--text mt-8" v-if="vitrueDeveloper">
        <p class="text-h5 mb-1">
          {{ $t("account.personalSettings.integrations.title") }}
        </p>
        <p class="text-body-2 mb-8">
          {{ $t("account.personalSettings.integrations.explanation") }}
        </p>
        <add-slack @error="logAndShowError($event)" />
      </v-col>

      <v-col cols="12" class="black--text mt-8" v-if="isAdminUser">
        <p class="text-h5">
          {{ $t("account.personalSettings.quickStartTitle") }}
        </p>

        <v-row
          :justify="$vuetify.breakpoint.xs ? 'center' : 'start'"
          align="center"
          no-gutters
        >
          <v-col cols="auto">{{
            $t("account.personalSettings.quickStartToggle")
          }}</v-col
          ><v-col cols="auto"
            ><v-switch
              id="quickStartToggle"
              class="mt-1 pt-0 pl-2"
              dense
              hide-details
              v-model="adminChecklistToggle"
              color="secondary"
              @change="toggleQuickStart()"
          /></v-col>
        </v-row>
      </v-col>

      <v-snackbar v-model="showErrorMessage" timeout="2500" color="warning">
        {{ errorMessage }}
        <template v-slot:action="{ attrs }">
          <v-btn dark text v-bind="attrs" @click="showErrorMessage = false">
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="showSaveMessage" timeout="2500" color="success">
        {{ $t("account.personalSettings.snackBarMessages.saveSuccessful") }}
        <template v-slot:action="{ attrs }">
          <v-btn dark text v-bind="attrs" @click="showSaveMessage = false">
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from "aws-amplify";
import UpdateEmailDialog from "@/components/account/UpdateEmailDialog.vue";
import VitrueTooltip from "../common/VitrueTooltip.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import LanguageSelection from "@/components/common/LanguageSelection.vue";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import {
  getEndUserPersonalDetails,
  setEndUserPersonalDetails,
  getNotificationsForEndUser,
  updateNotificationForEndUser,
  setQuickStart
} from "@/customApi";
import { mapGetters, mapMutations } from "vuex";
import { updateCheckListFromFeatures } from "@/services/onboarding/adminOnboarding";
import AddSlack from "../common/slack/AddSlack.vue";

export default {
  name: "personal-settings",
  components: {
    VuePhoneNumberInput,
    VitrueTooltip,
    AddSlack,
    LanguageSelection,
    UpdateEmailDialog
  },
  data() {
    return {
      loadingData: false,
      loadingDataFailed: false,
      valid: false,
      loadedData: {
        name: "",
        countryCode: "",
        number: "",
        timeZone: ""
      },
      newData: {
        name: "",
        countryCode: "",
        number: "",
        formattedNumber: "",
        validNumber: true
      },
      savingResults: false,
      showErrorMessage: false,
      showSaveMessage: false,
      errorMessage: "",
      textNotifications: false,
      emailNotifications: false,
      adminChecklistToggle: false,
      currentUsername: "",
      showEmailUpdateDialog: false,
      rules: {
        nameValid(m) {
          return v =>
            /[`!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/i.test(v) === false ||
            v === "" ||
            m;
        }
      },
      notificationTimes: [
        {
          text: this.$t("account.personalSettings.notificationTimes.morning"),
          value: "10:00:00"
        },
        {
          text: this.$t("account.personalSettings.notificationTimes.afternoon"),
          value: "14:00:00"
        },
        {
          text: this.$t("account.personalSettings.notificationTimes.evening"),
          value: "19:00:00"
        }
      ],
      notificationTypes: [
        {
          settingsDto: {
            notificationName: "wellnessTaskReminder",
            email: false,
            text: false
          },
          text: "account.personalSettings.notificationsMissedDayWellnessOptIn"
        },
        {
          settingsDto: {
            notificationName: "adminMonthlyEmail",
            email: false,
            text: false
          },
          text: "account.personalSettings.notificationsAdminMonthlyEmails",
          adminOnly: true
        },
        {
          settingsDto: {
            notificationName: "assessmentCompleteNotification",
            email: false,
            text: false
          },
          text: "account.personalSettings.notificationAssessmentComplete",
          adminOnly: true
        }
      ],
      notificationSettings: [],
      developmentNotificationTypes: [
        {
          settingsDto: {
            notificationName: "wellnessEverydayReminder",
            email: false,
            text: false
          },
          text: "account.personalSettings.notificationsEveryDayWellnessOptIn"
        }
      ]
    };
  },
  async mounted() {
    if (this.vitrueDeveloper) {
      this.notificationTypes = this.notificationTypes.concat(
        this.developmentNotificationTypes
      );
    }

    await this.getPersonalInfo();
    let currentUser = await Auth.currentAuthenticatedUser();
    this.currentUsername = currentUser?.username;

    updateCheckListFromFeatures(this.featureIntros);
    this.getNotificationSettings();
  },
  computed: {
    ...mapGetters([
      "userEmail",
      "showTextNotifications",
      "vitrueDeveloper",
      "onboardingChecklist",
      "isAdminUser",
      "featureIntros",
      "testFeature"
    ]),
    hideSaveButton() {
      return (
        this.loadingData ||
        this.loadingDataFailed ||
        this.loadedDataMatchesNewData
      );
    },
    loadedDataMatchesNewData() {
      return (
        this.loadedData.name === this.newData.name &&
        (this.loadedData.number === this.newData.number ||
          this.loadedData.number === this.newData.formattedNumber) &&
        this.loadedData.countryCode === this.newData.countryCode
      );
    },
    textFieldColumnSize() {
      return this.$vuetify.breakpoint.smAndDown ? "12" : "4";
    },
    savebuttonWidth() {
      return this.$vuetify.breakpoint.smAndDown ? "width: 100%" : "";
    },
    notificationTimeZones() {
      let timeZones = this.$t("account.personalSettings.timeZones");
      return Object.keys(timeZones).map(x => {
        return {
          value: x,
          text: timeZones[x]
        };
      });
    },
    disableTextOptIn() {
      return !this.loadedData.number;
    },
    disableTextTimePreferences() {
      return !this.notificationTypes?.some(x => x.settingsDto.text);
    },
    showAdminChecklist() {
      return !!this.onboardingChecklist && !this.onboardingChecklist.dismissed;
    },
    userNameIsEmail() {
      return this.currentUsername === this.userEmail;
    }
  },
  watch: {
    onboardingChecklist(val) {
      this.adminChecklistToggle = !!val?.id && !val.dismissed && !val.completed;
    }
  },
  methods: {
    ...mapMutations(["setOnboardingChecklist", "removeOnboardingChecklist"]),
    setTelephoneInfo(newVal) {
      this.newData.validNumber = newVal.isValid;
      this.newData.countryCode = newVal.countryCode;
      this.newData.number = newVal.phoneNumber;
      this.newData.formattedNumber = newVal.formattedNumber;
    },
    async saveResults() {
      this.savingResults = true;
      let errorOccured = false;

      if (
        (!this.newData.validNumber && this.newData.number) ||
        (this.loadedData.countryCode != this.newData.countryCode &&
          !this.newData.validNumber)
      ) {
        this.errorMessage = this.$t(
          "account.personalSettings.snackBarMessages.invalidNumber"
        );
        this.showErrorMessage = true;
        errorOccured = true;
      }

      this.$refs.personalSettingsForm.validate();
      if (!this.valid) {
        this.errorMessage = this.$t(
          "account.personalSettings.snackBarMessages.genericSaveError"
        );
        this.showErrorMessage = true;
        errorOccured = true;
      }
      if (!errorOccured) {
        await this.savePersonalInfo();

        if (!this.newData.number) {
          await this.disableAllTextNotifications();
        }
      }
      this.savingResults = false;
      this.showSaveMessage = !this.showErrorMessage;
    },
    async disableAllTextNotifications() {
      this.notificationTypes.forEach(t => {
        if (t.settingsDto.text) {
          t.settingsDto.text = false;
          this.saveNotificationSettings(t.settingsDto);
        }
      });
    },
    async savePersonalInfo() {
      try {
        let userDto = {
          name: this.newData.name,
          countryCode: this.newData.countryCode,
          phoneNumber: this.newData.formattedNumber,
          timeZone: this.newData.timeZone
        };
        await setEndUserPersonalDetails(userDto);
        this.loadedData = { ...this.newData };
      } catch (err) {
        this.logAndShowError("genericSaveError");
      } finally {
        this.savingResults = false;
      }
    },
    async getPersonalInfo() {
      try {
        this.loadingData = true;
        let userDetails = await getEndUserPersonalDetails();
        this.loadedData.name = userDetails.name;
        this.loadedData.countryCode = userDetails.countryCode;
        this.loadedData.number = userDetails.phoneNumber;
        this.loadedData.timeZone = userDetails.timeZone;
        this.newData = { ...this.loadedData };
      } catch (err) {
        this.logAndShowError("genericLoadError");
        this.loadingDataFailed = true;
      } finally {
        this.loadingData = false;
      }
    },
    async getNotificationSettings() {
      try {
        this.notificationSettings = await getNotificationsForEndUser();
        this.assignNotificationSettings(this.notificationSettings);

        this.notificationSettings.forEach(x => {
          if (!x.time) x.time = "10:00:00";
        });
      } catch (err) {
        this.logAndShowError("genericLoadError");
      }
    },
    async saveNotificationSettings(notificationDto) {
      try {
        await updateNotificationForEndUser(notificationDto);
      } catch (err) {
        this.logAndShowError("genericSaveError");
      }
    },
    async toggleQuickStart() {
      let payload = {
        showAdminChecklist: this.adminChecklistToggle
      };
      var response = await setQuickStart(payload);
      if (response.adminChecklist) {
        this.setOnboardingChecklist(response.adminChecklist);
      } else {
        this.removeOnboardingChecklist();
      }
    },
    assignNotificationSettings(settings) {
      this.notificationTypes.forEach(t => {
        var settingsToAssign = settings.find(
          x => x.notificationName === t.settingsDto.notificationName
        );
        if (settingsToAssign) {
          t.settingsDto = settingsToAssign;
        }
      });
    },
    logAndShowError(errorType) {
      this.errorMessage = this.$t(
        "account.personalSettings.snackBarMessages." + errorType
      );
      this.showErrorMessage = true;
    }
  }
};
</script>

<style scoped>
.hideOverflow .v-select__selection {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}

.hideOverflow .v-select__selections {
  max-width: 70%;
}
</style>
